import React from 'react';
import { StyleSheet, Animated } from 'react-native';
const THUMB_SIZE = 40;
export const SliderThumb = ({ Component, isVisible, onLayout, style, start, color, vertical, ...props }) => {
    const ThumbComponent = Component || Animated.View;
    const axis = vertical ? 'translateY' : 'translateX';
    const thumbPosition = [{ [axis]: start }];
    const styleTransform = (style && style.transform) || [];
    const visibleStyle = isVisible ? {} : { height: 0, width: 0 };
    return (<ThumbComponent testID="RNE__Slider_Thumb" onLayout={onLayout} style={StyleSheet.flatten([
            {
                backgroundColor: color,
                transform: [...thumbPosition, ...styleTransform],
                ...visibleStyle,
            },
            styles.thumb,
            style,
        ])} {...props}/>);
};
const styles = StyleSheet.create({
    thumb: {
        position: 'absolute',
        width: THUMB_SIZE,
        height: THUMB_SIZE,
        borderRadius: THUMB_SIZE / 2,
    },
});
